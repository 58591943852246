import React, { useState, useEffect, useMemo } from 'react';
import './style.css';
import Landing from '../components/Landing';
import {
    Row, Col, Container,
} from 'react-bootstrap';
import { IMGS } from '../util/constants';

const NumbersComponent = () => (
    <div>
        <Container className="peak-block peak-home-impact-block pt-0 pb-0">
            <div className="peak-home-impact-text mt-5">
                <div className="text-h3">
                    {/* Row 1 */}
                    <Row className="pb-3 justify-content-center text-center">
                        {/* For small screens and larger */}
                        <Col sm={5} className="d-none d-sm-block text-right pb-3">
                            <h3><span className="text-dark-blue"><b>5</b></span></h3>
                        </Col>
                        <Col sm={7} className="d-none d-sm-block p-0 text-left impact-font-size">
                            <h3>minute walk to Binus</h3>
                        </Col>

                        {/* For extra small screens */}
                        <Col xs={12} className="d-sm-none text-center pb-3">
                            <h3><span className="text-dark-blue"><b>5</b></span> minute walk to Binus</h3>
                        </Col>
                    </Row>

                    {/* Row 2 */}
                    <Row className="pb-3 justify-content-center text-center">
                        {/* For small screens and larger */}
                        <Col sm={5} className="d-none d-sm-block text-right pb-3">
                            <h3><span className="text-aqua"><b>10+</b></span></h3>
                        </Col>
                        <Col sm={7} className="d-none d-sm-block p-0 text-left impact-font-size">
                            <h3>food services within 50 metres</h3>
                        </Col>

                        {/* For extra small screens */}
                        <Col xs={12} className="d-sm-none text-center pb-3">
                            <h3><span className="text-aqua"><b>10+</b></span> food services within 50 metres</h3>
                        </Col>
                    </Row>

                    {/* Row 3 */}
                    <Row className="pb-3 justify-content-center text-center">
                        {/* For small screens and larger */}
                        <Col sm={5} className="d-none d-sm-block text-right pb-3">
                            <h3><span className="text-pink"><b>1000+</b></span></h3>
                        </Col>
                        <Col sm={7} className="d-none d-sm-block p-0 text-left impact-font-size">
                            <h3>residents homed since 2010</h3>
                        </Col>

                        {/* For extra small screens */}
                        <Col xs={12} className="d-sm-none text-center pb-3">
                            <h3><span className="text-pink"><b>1000+</b></span> residents homed since 2010</h3>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    </div>
);

const HomeComponent = () => {
    const images = useMemo(() => [IMGS.building1, IMGS.building2, IMGS.bed1], []);
    const [currentImage, setCurrentImage] = useState(images[0]);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000);
    
        return () => clearInterval(intervalId);
    }, [images.length]); 

    useEffect(() => {
        setCurrentImage(images[imageIndex]);
    }, [imageIndex, images]); 

    return (
        <div>
            <Landing image={currentImage}>
                <h1 className="pb-2">Experience Elevated Living</h1>
                <p>
                Discover The Peak – your sanctuary of comfort and convenience. 
                Nestled in a vibrant community, we offer a unique blend of modern amenities, safety, and serene green spaces, all tailored to enhance your living experience.
                </p>
            </Landing>
            <br/>
            <NumbersComponent />
        </div>
    );
};

export default HomeComponent;