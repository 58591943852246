import * as React from 'react';
import {
    Container, Row, Col, Image,
} from 'react-bootstrap';
import { IMGS } from '../util/constants';

const Footer = () => {
    return (
        <div className="peak-footer pt-5 d-flex align-items-end">
            <Container fluid className="p-0 mr-5">
                <Row>
                <Col md className="mb-n3 peak-footer-graphics mt-auto"></Col>
                    <Col md className="mt-auto mb-5 text-right">
                        <h5>The Peak</h5>
                        <p>
                        Join our vibrance, where every day is an opportunity to thrive <br />in a space that values your comfort, security, and well-being. 
                        </p>
                        <Row className="peak-social-media justify-content-end">
                        <a
                                href={'https://wa.me/6287775870922'}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={IMGS.wa}
                                    alt="whatsapp_logo"
                                />
                            </a>
                            <a
                                href={'https://www.instagram.com/thepeak.jkt'}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={IMGS.ig}
                                    alt="instagram_logo"
                                />
                            </a>
                        </Row>
                    </Col>
                    
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
