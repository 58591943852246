import React from 'react';
import {
    Col, Row, Container, Image,
} from 'react-bootstrap';
import { IMGS } from '../util/constants';

const Graph1Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pr-md-5 pb-5">
            <h4>Stay Cool and Comfortable</h4> 
            <p>
            Experience year round comfort with our state-of-the-art air conditioning systems, ensuring your living space is a cool oasis, regardless of the weather outside.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <div className="d-inline-block text-center pb-4 w-100">
                <Image src={IMGS.ac} fluid className="w-30" />
            </div>
        </Col>
    </Row>
);

const Graph2Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5 order-md-2">
            <h4>
            Sparkling Clean, Every Day
            </h4>
            <p>
            With our bathrooms maintained daily, you'll enjoy the highest standards of hygiene and comfort, ensuring a refreshingly clean start and end to your day.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <div className="d-inline-block text-center pb-4 w-100">
                <Image src={IMGS.bath} fluid className="w-30" />
            </div>
        </Col>
    </Row>
);

const Graph3Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pr-md-5 pb-5">
            <h4>Ready for Living</h4>
            <p>
            Our rooms come fully furnished with stylish and practical pieces, including a comfortable bed, a spacious desk and chair for work or study, and a wardrobe for your belongings—just move in and feel at home.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <div className="d-inline-block text-center pb-4 w-100">
                <Image src={IMGS.furnished} fluid className="w-30" />
            </div>
        </Col>
    </Row>
);

const Graph4Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5 order-md-2">
            <h4>Shared Spaces, Culinary Delights</h4>
            <p>
            Our communal kitchen is maintained regularly, providing you with a clean and welcoming space to cook, meet fellow residents, and indulge in the pleasure of shared meals.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <div className="d-inline-block text-center pb-4 w-100">
                <Image src={IMGS.kitchen} fluid className="w-30" />
            </div>
        </Col>
    </Row>
);

const Graph5Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pr-md-5 pb-5">
            <h4>Fresh Wardrobe Weekly</h4>
            <p>
            Enjoy the convenience of our weekly laundry service, designed to keep your clothes clean and your living space clutter-free, without the hassle of doing it yourself.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <div className="d-inline-block text-center pb-4 w-100">
                <Image src={IMGS.laundry} fluid className="w-30" />
            </div>
        </Col>
    </Row>
);

const Graph6Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5 order-md-2">
            <h4>Park with Ease</h4>
            <p>
            Take advantage of our secure motorbike parking space available at no extra cost, offering you peace of mind and a hassle-free parking experience every time.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <div className="d-inline-block text-center pb-4 w-100">
                <Image src={IMGS.park} fluid className="w-30" />
            </div>
        </Col>
    </Row>
);

const Graph7Component = () => (
    <Row className="pt-5 pb-5" data-aos="fade-up">
        <Col md={6} xs={12} className="mt-auto mb-auto pr-md-5 pb-5">
            <h4>Hydration Station on Every Floor</h4>
            <p>
            Stay hydrated with ease thanks to our free flow water stations on each floor, offering both cold and hot options for your drinking, cooking, or brewing needs.
            </p>
        </Col>
        <Col md={6} xs={12} className="mt-auto mb-auto pl-md-5 pb-5">
            <div className="d-inline-block text-center pb-4 w-100">
                <Image src={IMGS.water} fluid className="w-30" />
            </div>
        </Col>
    </Row>
);

const AmenitiesComponent = () => (
<div>
        <Container fluid className="peak-block bg-white">
            <Container>
                <Row className="pb-4" data-aos="fade-up">
                    <Col>
                        <h1 className="text-center pb-3">Our Amenities</h1>
                    </Col>
                </Row>
                <Graph1Component />
                <Graph2Component />
                <Graph3Component />
                <Graph4Component />
                <Graph5Component />
                <Graph6Component />
                <Graph7Component />
            </Container>
        </Container>
    </div>
);

export default AmenitiesComponent;
