import React from 'react';
import {
    Row, Col, Image, Container,
} from 'react-bootstrap';
import { IMGS } from '../util/constants';
import './style.css';

const GoogleMap = () => (
    <div className="map-container">
    <iframe
        title="Google Map Location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.4718559766898!2d106.78228577473699!3d-6.201312693786429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6c305f768b9%3A0x5577f07260e5d5bd!2sKost%20The%20Peak!5e0!3m2!1sen!2sca!4v1703893857481!5m2!1sen!2sca"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
    </div>
);

const AboutComponent = () => (
    <div id="peak-about-desc">
        <Container className="peak-block">
            <Row>
                <Col md={5} data-aos="fade-right">
                    <h3 className="pb-4 text-dark-blue">Redefining Comfort and Convenience for a Modern, Connected Lifestyle</h3>
                </Col>
                <Col md={7} data-aos="fade-left">
                    <p>
                        At The Peak, our mission is to provide more than just a place to live; we aim to create a vibrant, inclusive community where every individual feels at home. 
                        We're dedicated to fostering an environment that supports academic pursuits, personal growth, and social interaction, all within a setting that prioritizes safety, sustainability, and well-being. 
                    </p>
                    <p>    
                        Our focus is on delivering a living experience that's both enriching and effortless, ensuring our residents have the perfect balance of private sanctuary and communal engagement. 
                        By continuously evolving to meet the dynamic needs of our diverse population, we commit to being not just a residence, but a cornerstone of memorable and fulfilling university life.
                    </p>
                </Col>
            </Row>
        </Container>
        <Container className="peak-block peak-about-impact">
            <h3 className="pb-4 text-dark-blue text-center">Holistic Living</h3>
            <Row>
                <Col lg={5} className="d-none d-lg-block peak-about-impact-add-img">
                    <Image className="pb-3 h-50" src={IMGS.parking} fluid />
                    <Image className="h-50" src={IMGS.stairs}  fluid />
                </Col>
                <Col className="pl-lg-0">
                    <Image className="pb-3" src={IMGS.cctv} fluid />
                    <p className="pt-lg-3 pl-lg-3 pr-lg-3">
                        We epitomize holistic living with a focus on cleanliness, safety, and a connection with nature. 
                        Our spotless, well-maintained environment and robust security measures ensure a peaceful, secure residence. 
                        Surrounded by greenery, The Peak offers a serene oasis, nurturing both mental and physical well-being. 
                        It's a place where you don't just reside; you thrive in a community dedicated to a balanced and healthy lifestyle.
                    </p>
                </Col>
            </Row>
        </Container>
        <Container>
        <h3 className="pb-4 text-dark-blue text-center mt-5">Discover Your Ideal Location</h3>
        <center>
            <p>Explore The Peak's prime location on Google Maps! 
                Conveniently situated close to essential amenities and bustling local eateries, our residence offers the perfect balance of accessibility and tranquility. 
                Dive into our location to see just how close you are to everything you need. 
                Don't forget to view our reviews to see why our residents love calling The Peak their home.
            </p>
            <GoogleMap />
        </center>
        </Container>
    </div>
);

export default AboutComponent