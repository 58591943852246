export const ROUTES = {
    LANDING: '/',
    ABOUT: '/about',
    ROOMS: '/rooms',
    AMENITIES: '/amenities',
};

export const IMGS = {
    wa: require('../assets/whatsapp.png'),
    fb: require('../assets/facebook.png'),
    ig: require('../assets/instagram.png'),
    logo: require('../assets/peakLogo.png'),
    ac: require('../assets/ac.PNG'),
    bath: require('../assets/bath.PNG'),
    furnished: require('../assets/furnished.PNG'),
    kitchen: require('../assets/kitchen.PNG'),
    laundry: require('../assets/laundry.PNG'),
    park: require('../assets/park.PNG'),
    water: require('../assets/water.PNG'),
    bed1: require('../assets/bed_landscape.JPG'),
    bed2: require('../assets/bed_landscape2.JPG'),
    bed3: require('../assets/bed_landscape3.JPG'),
    building1: require('../assets/building.jpg'),
    building2: require('../assets/building_portrait.jpg'),
    cctv: require('../assets/cctv.JPG'),
    dispenser: require('../assets/dispenser.JPG'),
    stairs: require('../assets/stairs.JPG'),
    parking: require('../assets/parking.JPG'),
    tree: require('../assets/footer-graphics.svg'),
}