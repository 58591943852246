import React from 'react';
import './style.css';  
import {
    Row, Col, Container, Image,
} from 'react-bootstrap';

import { IMGS } from '../util/constants';

const RoomComponent = () => {
    return (
        <div className="peak-home-portfolio-section mt-5">
            <Row className="justify-items-center pb-4">
                <Col>
                    <h3 className="text-center font-weight-bold pb-5">Our Rooms</h3>
                </Col>
            </Row>
            <Container fluid className="peak-block peak-home-portfolio-block p-0">
                <div className="peak-home-portfolio d-inline-flex w-100">
                    <Row className="w-100 m-0 d-none d-sm-flex">
                        <Col lg={6} md={5} sm={4} className="p-0" />
                        <Col lg={6} md={7} sm={8} className="p-0">
                            <Image src={IMGS.bed1} className="w-100" fluid />
                        </Col>
                    </Row>
                    <div className="peak-home-portfolio-text peak-home-portfolio-left" style={{ borderLeft: '1.5rem solid #69995D' }}>
                        <h4>Classic</h4>
                        <br />
                        <p>
                        Our Classic Room offers a comfortable and efficient living space, perfectly suited for those seeking a cozy, no-fuss environment. 
                        It's an ideal choice for students and young professionals who appreciate simplicity and functionality.
                        </p>
                    </div>
                </div>
            </Container>
            <Container fluid className="peak-block peak-home-portfolio-block p-0">
                <div className="peak-home-portfolio d-inline-flex w-100">
                    <Row className="w-100 m-0 d-none d-sm-flex">
                        <Col lg={6} md={7} sm={8} className="p-0 w-md-50">
                            <Image src={IMGS.bed1} className="w-100" fluid />
                        </Col>
                        <Col lg={6} md={5} sm={4} className="p-0 w-md-50" />
                    </Row>
                    <div className="peak-home-portfolio-text peak-home-portfolio-right" style={{ borderLeft: '1.5rem solid #DDA77B' }} data-aos="fade-up">
                        <h4>Classic + TV</h4>
                        <br />
                        <p>
                        The Classic Plus TV room elevates your living experience by adding a touch of entertainment. 
                        Enjoy all the comforts of our Classic Room, now with the added luxury of a personal TV, making it a perfect space for relaxation and leisure.
                        </p>
                    </div>
                </div>
            </Container>
            <Container fluid className="peak-block peak-home-portfolio-block p-0">
                <div className="peak-home-portfolio d-inline-flex w-100">
                    <Row className="w-100 m-0 d-none d-sm-flex">
                        <Col lg={6} md={5} sm={4} className="p-0 w-md-50" />
                        <Col lg={6} md={7} sm={8} className="p-0 w-md-50">
                            <Image src={IMGS.bed1} className="w-100" fluid />
                        </Col>
                    </Row>
                    <div className="peak-home-portfolio-text peak-home-portfolio-left" style={{ borderLeft: '1.5rem solid #258EA6' }} data-aos="fade-up">
                        <h4>Ensuite Classic</h4>
                        <br />
                        <p>
                        Our Classic Plus Bathroom room combines the charm of our Classic Room with the privacy and convenience of an en-suite bathroom. 
                        It's designed for those who value their own space and prefer the ease of personal amenities.
                        </p>
                    </div>
                </div>
            </Container>
            <Container fluid className="peak-block peak-home-portfolio-block p-0">
                <div className="peak-home-portfolio d-inline-flex w-100">
                    <Row className="w-100 m-0 d-none d-sm-flex">
                        <Col lg={6} md={7} sm={8} className="p-0 w-md-50">
                            <Image src={IMGS.bed1} className="w-100" fluid />
                        </Col>
                        <Col lg={6} md={5} sm={4} className="p-0 w-md-50" />
                    </Row>
                    <div className="peak-home-portfolio-text peak-home-portfolio-right" style={{ borderLeft: '1.5rem solid #D64933' }} data-aos="fade-up">
                        <h4>Extra Large</h4>
                        <br />
                        <p>
                        The Extra Large Room is the epitome of spacious living at The Peak. 
                        Ideal for those who desire more room to live, work, and play, this generously sized space offers ample room for all your needs, making it perfect for extended stays or anyone who enjoys a larger living area.
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default RoomComponent;
