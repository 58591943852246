import React from 'react';
import {
    Row, Col, Container,
} from 'react-bootstrap';

const Landing = ({ image, children }) => (
    <Container
        data-aos="fade-right"
        fluid
        className="peak-block peak-landing"
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})` }}
    >
        <Container>
            <Row className="h-100 peak-landing-margin">
                <Col lg={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} className="text-right text-white text-image-alignment">
                    { children }
                </Col>
            </Row>
        </Container>
    </Container>
);

export default Landing;
