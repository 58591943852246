import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Navbar, Container, Col, Nav, Image, 
} from 'react-bootstrap';

import { ROUTES, IMGS } from '../util/constants';
import '../assets/style/hamburger.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(0);
    const [showBcg, setShowBcg] = useState(0);
    const [navHide, setNavHide] = useState(0);
    const [lastScroll, setLastScroll] = useState(0);

    // navbar scrolling variables
    const navBarThresh = 100;

    useEffect(() => {
        // hides navbar when user scrolls down and makes background transparent when at top
        const handleScroll = () => {
            if (!isOpen) {
                if (window.pageYOffset !== 0) setShowBcg(1);
                else setShowBcg(0);

                if (window.pageYOffset > (lastScroll + navBarThresh) || window.pageYOffset < (lastScroll - navBarThresh)) {
                    if (window.pageYOffset > lastScroll) setNavHide(1);
                    else setNavHide(0);
                    setLastScroll(window.pageYOffset);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return function cleanup() {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const onNavigate = () => {
        window.scrollTo(0, 0);
    };

    // hamburger menu animation on click
    const handleHamburgerClick = () => {
        setIsOpen(!isOpen);
        if (!isOpen) setShowBcg(0);
        onNavigate();
    };

    return (
        <div>
            <Navbar
                className={`peak-navbar pl-3 pl-lg-0 pl-xl-3 ${
                    navHide ? 'nav-hide' : ''
                } ${showBcg ? 'nav-bcg' : ''}`}
            >
                <Container fluid className="peak">
                    <Col
                        className={`p-1 p-md-0 p-lg-1 peak-badge ${
                            isOpen ? 'badge-hide' : ''
                        }`}
                    >
                        <NavLink to={ROUTES.LANDING} exact className="peak-navbar-logo">
                            <Image
                                src={IMGS.logo}
                                className="p-0 p-xl-3 pb-0"
                                fluid
                            />
                        </NavLink>
                    </Col>

                    <div className="peak-nav-links">
                        <NavLink
                            activeClassName="active"
                            to={ROUTES.ABOUT}
                            onClick={() => {
                                onNavigate();
                            }}
                        >
                            About Us
                        </NavLink>
                        <NavLink
                            activeClassName="active"
                            to={ROUTES.ROOMS}
                            onClick={() => {
                                onNavigate();
                            }}
                        >
                            Rooms
                        </NavLink>
                        <NavLink
                            activeClassName="active"
                            to={ROUTES.AMENITIES}
                            onClick={() => {
                                onNavigate();
                            }}
                        >
                            Amenities
                        </NavLink>
                        <a id="sponsor-button" href={'https://wa.me/6282114810922'} target='blank'>
                            Contact Us
                        </a>
                    </div>

                    <div className="peak-mobile-hamburger">
                        <div
                            onClick={handleHamburgerClick}
                            className={`hamburger hamburger--vortex ${
                                isOpen ? 'is-active' : ''
                            }`}
                        >
                            <div className="hamburger-box">
                                <div className="hamburger-inner" />
                            </div>
                        </div>
                    </div>
                </Container>
            </Navbar>

            <Nav className={`peak-mobile-nav ${isOpen ? 'mobile-nav-show' : ''}`}>
                <div className="peak-nav-links">
                    <NavLink
                        activeClassName="active"
                        to={ROUTES.ABOUT}
                        className="show-nav-link"
                        onClick={handleHamburgerClick}
                    >
                        About Us
                    </NavLink>
                    <NavLink
                        activeClassName="active"
                        to={ROUTES.ROOMS}
                        className="show-nav-link"
                        onClick={handleHamburgerClick}
                    >
                        Rooms
                    </NavLink>
                    <NavLink
                        activeClassName="active"
                        to={ROUTES.AMENITIES}
                        className="show-nav-link"
                        onClick={handleHamburgerClick}
                    >
                        Amenities
                    </NavLink>
                    <NavLink
                        activeClassName="active"
                        to={'https://wa.me/6282114810922'}
                        className="show-nav-link"
                        onClick={handleHamburgerClick}
                        target='blank'
                    >
                        Contact Us
                    </NavLink>
                </div>
            </Nav>
        </div>
    );
};

export default Header;
