import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { ROUTES } from './util/constants';

// import Banner from './components/Banner';
import Footer from './components/Footer';
import Header from './components/Header';

import HomeComponent from './home/HomeComponent';
import AboutComponent from './about/AboutComponent';
import RoomsComponent from './room/RoomsComponent';
import AmenitiesComponent from './amenities/AmenitiesComponent';

const App = () => {

    return (
        <Router>
            {/* <Banner /> */}
            <Header />
            <div className="peak-content">
            <Routes>
    <Route path={ROUTES.LANDING} element={<HomeComponent />} />
    <Route path={ROUTES.ABOUT} element={<AboutComponent />} />
    <Route path={ROUTES.ROOMS} element={<RoomsComponent />} />
    <Route path={ROUTES.AMENITIES} element={<AmenitiesComponent />} />
</Routes>
            </div>
            <Footer />
        </Router>
    );
};

export default App;
